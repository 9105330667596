<template>
  
  <div>
    <div class="check-all-holder col-2" v-if="!hideSelection">
      <v-checkbox v-model="selectedAll" label="alle auswählen" @click="allCheckCLicked"></v-checkbox>
    </div>
    <v-data-table
      :headers="headers"
      :items="tableData"
      :show-select="showSelect"
      :singleSelect="singleSelect"
      item-key="id"
      :item-class="lockedRowClass"
      v-model="mySelectedItems"
      :search="performSearch"
      :page.sync="pageHistory"
      @update:page="pageChange"
      :footer-props="{
      'items-per-page-text':'Zeilen pro Seite'
  }"
    >
      <template #item.buttons="{item}">
        <div class="buttons-holder">
          <!-- <v-icon
            v-if="item.isTree"
            v-for="action in actions"
            :key="action.id"
            :disabled="isButtonDisabled(action, item)"
            @click="$emit(`${action.action}`, item)"
            class="green--text custom-action-icon"
            >{{ action.icons }}</v-icon
          > -->
           <img v-if="item.isTree" v-for="action in actions"
            v-show="!isButtonDisabled(action, item)"
            :key="action.id"
            :class="isButtonDisabled(action, item)? 'disabled' : ''"
            @click="$emit(`${action.action}`, item)"
            class="green--text custom-action-icon" v-bind:src="action.icons"/> 


            <img v-else
            v-show="action.show"
            :key="action.id"
            @click="$emit(`${action.action}`, item)"
            class="green--text custom-action-icon"
            v-bind:src="action.icons"/> 

          <!-- <v-icon
            v-else
            v-show="action.show"
            :key="action.id"
            @click="$emit(`${action.action}`, item)"
            class="green--text custom-action-icon"
            >{{ actions[0].icons }}</v-icon
          > -->
        </div>
      </template>
      <template v-slot:item.colorTypeId="{ item }">
        <span :class="getColorClass(item.colorTypeId)"></span>
      </template>
      <template v-slot:item.name="{ item }">
        <div v-if="item.name" class="d-flex" style="align-items: center;">
          <!-- <v-icon v-if="item.isTree" class="custom-map-area-icon"
            >mdi mdi-tree-outline</v-icon
          > -->
          <img v-if="item.isTree && !showActualTrees" class="custom-map-area-icon-archive" src="../../assets/images/icons/archived-tree.svg"/> 
          <img v-else-if="item.isTree" class="custom-map-area-icon" src="../../assets/images/icons/baumliste.svg" /> 
          <v-icon v-else class="custom-map-area-icon"
            >mdi mdi-texture-box</v-icon
          >
          <span>{{ item.name }}</span>
        </div>
      </template>

       <template #header.data-table-select="{ }"> </template> 

      <template #item.data-table-select="{ item }">
        <v-checkbox
          v-if="!hideSelection"
          :id="`${item.id}-${item.areaId}`"
          color="#1DB954"
          :off-icon="offIcon"
          :on-icon="onIcon"
          :value="item"
          :multiple="true"
          v-model="mySelectedItems"
          @click="$emit('selectedRow', mySelectedItems)"
        ></v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../../main";
export default {
  props: ["headers", "tableData", "actions", "showSelect","hideSelection", "singleSelect", "userCanView", "userCanEdit", "userCanDelete", "showActualTrees"],
  data() {
    return {
      selectedItems: [],
      selectedAll: false,
      currentPage: 1,
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters(["getSearchquery"]),
    pageHistory: {
      get() {
        const savedPage = JSON.parse(localStorage.getItem('tableTreeCurrentPage'));
        if (this.tableData.length > 0 && savedPage !== null) {
          return savedPage;
        } else {
          return 1;
        }
      },
      set(value) {
        localStorage.setItem('tableTreeCurrentPage', value);
      }
    },
     performSearch() {
      return this.getSearchquery;
    },
    mySelectedItems: {
      get() {
        if (this.singleSelect)
          return [this.selectedItems[this.selectedItems.length - 1]];
        return this.selectedItems;
      },
      set(value) {
        if (typeof value !== "object") this.selectedItems = [value];
        else this.selectedItems = value;
      }
    },
    offIcon() {
      return this.singleSelect
        ? "mdi-checkbox-blank-circle-outline"
        : "mdi-checkbox-blank-outline";
    },
    onIcon() {
      return this.singleSelect
        ? "mdi-checkbox-blank-circle"
        : "mdi-checkbox-marked";
    },
    lockedRowClass() {
      return item => (item.isLocked ? "locked-row" : "");
    },
      
  },
  mounted() {
    // if(this.selectedAll === true){
    //   alert();
    // eventBus.$on("onTableFilter", () => (this.selectedItems = []));
    // }
    // const savedPage = localStorage.getItem('tableTreeCurrentPage');
    // setTimeout(() => {
    //   if(this.tableData){
    //     this.currentPage = savedPage ? savedPage : 1
    //   }
    // }, 500)
    this.localSearch = this.getSearchquery;
  },
  // destroyed() {
  //   eventBus.$off("onTableFilter");
  // },
  methods: {
      pageChange(newPage) {
      localStorage.setItem("tableTreeCurrentPage", newPage);
    },
    getColorClass(colorType) {
      switch (colorType) {
        case 1:
          return 'circle red-circle';
        case 2:
          return 'circle green-circle';
        case 3:
          return 'circle blue-circle';
        case 4:
          return 'circle yellow-circle';
        case 5:
          return 'circle gray-circle';
        default:
          return 'circle gray-circle';
      }
    },
    // onSelectAllInputHandler(value) {
    //   if (value) {
    //     this.allChecked = this.selectedItems.map(m => m.id);
    //   } else {
    //     this.allChecked = [];
    //   }
    // },
    //    handleSearch(query) {
    //   this.getSearchquery = query;
    // },

    isButtonDisabled(action, item) {
      /* if user can view from backend and action is view, dont disable button */
      /* if user can edit, and action is edit, and the task is not finished, dont disable button */
      if (this.userCanView === true && action.action === "view" && this.showActualTrees) {
        return false;
      } else if (this.userCanEdit === true && action.action === "edit" && this.showActualTrees) {
        return false;
      } else if (action.action === "archive" && !this.showActualTrees) {
        return false;
      }  else {
        return true;
      }
    },
    allCheckCLicked() {
      if(this.selectedAll === true) {
        this.selectedItems = this.tableData;
        this.$emit('selectedRow', this.mySelectedItems);
      } else {
        this.selectedItems = [];
      }
    },
 }

};
</script>

<style lang="scss">
.check-all-holder {
  padding-left: 16px;
  padding-top: 16px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
.custom-action-icon {
  border: 1px solid #494949;
  padding: 8px;
  border-radius: 5px;
  width: 42px;
  height: 42px;

  &::after {
    border-radius: 5px;
    width: 42px;
    height: 42px;
    transform: scale(1);
  }
}

.custom-map-area-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}

.custom-map-area-icon-archive {
  margin-right: 5px;
  width: 30px;
  height: 30px;
}

.buttons-holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.locked-text {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  color: white;
  padding: 4px;
}
/* Red */
.red-circle {
  background-color: #c82210;
}

/* Green */
.green-circle {
  background-color: #1db954;
}

/* Blue */
.blue-circle {
  background-color: #118afa;
}

/* Yellow */
.yellow-circle {
  background-color: #fff500;
}

/* Gray */
.gray-circle {
  background-color: gray;
}

.circle {
  display: inline-block;
  width: 20px; 
  height: 20px;
  border-radius: 50%; 
  margin-right: 10px; 
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.hideSelection)?_c('div',{staticClass:"check-all-holder col-2"},[_c('v-checkbox',{attrs:{"label":"alle auswählen"},on:{"click":_vm.allCheckCLicked},model:{value:(_vm.selectedAll),callback:function ($$v) {_vm.selectedAll=$$v},expression:"selectedAll"}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tableData,"show-select":_vm.showSelect,"singleSelect":_vm.singleSelect,"item-key":"id","item-class":_vm.lockedRowClass,"search":_vm.performSearch,"page":_vm.pageHistory,"footer-props":{
    'items-per-page-text':'Zeilen pro Seite'
}},on:{"update:page":[function($event){_vm.pageHistory=$event},_vm.pageChange]},scopedSlots:_vm._u([{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"buttons-holder"},_vm._l((_vm.actions),function(action){return (item.isTree)?_c('img',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isButtonDisabled(action, item)),expression:"!isButtonDisabled(action, item)"}],key:action.id,staticClass:"green--text custom-action-icon",class:_vm.isButtonDisabled(action, item)? 'disabled' : '',attrs:{"src":action.icons},on:{"click":function($event){return _vm.$emit(("" + (action.action)), item)}}}):_c('img',{directives:[{name:"show",rawName:"v-show",value:(action.show),expression:"action.show"}],key:action.id,staticClass:"green--text custom-action-icon",attrs:{"src":action.icons},on:{"click":function($event){return _vm.$emit(("" + (action.action)), item)}}})}),0)]}},{key:"item.colorTypeId",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColorClass(item.colorTypeId)})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[(item.isTree && !_vm.showActualTrees)?_c('img',{staticClass:"custom-map-area-icon-archive",attrs:{"src":require("../../assets/images/icons/archived-tree.svg")}}):(item.isTree)?_c('img',{staticClass:"custom-map-area-icon",attrs:{"src":require("../../assets/images/icons/baumliste.svg")}}):_c('v-icon',{staticClass:"custom-map-area-icon"},[_vm._v("mdi mdi-texture-box")]),_c('span',[_vm._v(_vm._s(item.name))])],1):_vm._e()]}},{key:"header.data-table-select",fn:function(ref){return undefined}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [(!_vm.hideSelection)?_c('v-checkbox',{attrs:{"id":((item.id) + "-" + (item.areaId)),"color":"#1DB954","off-icon":_vm.offIcon,"on-icon":_vm.onIcon,"value":item,"multiple":true},on:{"click":function($event){return _vm.$emit('selectedRow', _vm.mySelectedItems)}},model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}}):_vm._e()]}}]),model:{value:(_vm.mySelectedItems),callback:function ($$v) {_vm.mySelectedItems=$$v},expression:"mySelectedItems"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
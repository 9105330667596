<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="timeoutSnackbar" top color="danger">
      <div>{{ snackbarMessage }}</div>
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click.prevent="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="custom-buttons-holder">
      <v-container fluid>
        <v-row justify="end">
          <v-col cols="12">
            <v-row class="first-row mb-3">
              <v-col cols="1" v-if="!hideFilters">
                <a class="filter " @click="viewSelectedItemOnMap">
                  <img class="karte" src="../../../assets/images/map.png" alt="" />
                  <span>Karte</span>
                  <span class="information" v-if="showInfo">
                    Sie müssen mindestens einen Baum oder ein Gebiet auswählen.
                  </span>
                </a>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.lgAndUp || $vuetify.breakpoint.xlAndUp ? 2 : 1" v-if="!hideFilters">
                <a class="filter" @click="filtersTrigger">
                  <img class="karte" src="../../../assets/images/icons/filter.svg" alt="" />
                  <span>Filter</span>
                </a>
              </v-col>
              <v-col cols="2" v-if="!hideFilters">
                <a class="filter">
                  <v-switch inset v-model="showActualTrees" @change="fetchArchivedTrees"></v-switch>
                  <span>{{ showActualTrees ? 'Aktuelle Bäume' : 'Archivierte Bäume' }}</span>
                </a>
              </v-col>
              
              <v-col cols="2" v-if="!hideFilters">
                <label class="import" for="fileInput">
                  <img class="custom-icon" src="../../../assets/images/icons/import.svg" />
                  <span>Baum importieren</span>
                  <input type="file" id="fileInput" style="display: none" @change="handleFileUpload" accept=".xlsx" />
                </label>
              </v-col>   
               <v-col cols="2" v-if="!hideFilters">
                <a class="print" @click="downloadExcel">
                  <!-- <img class="custom-icon" src="../../../assets/images/icons/export.svg" /> -->
                  <span class="mdi mdi-open-in-new custom-icon"></span>
                  <span>Baum Exportieren</span>
                </a>
              </v-col>
              <v-col class="col-sm-4 col-md-4 col-lg-3">
                <v-btn block size="x-large" height="54px" color="#1db954" class="new-btn"
                  @click.prevent="goToCreateTree">
                  <img class="karte" src="../../../assets/images/icons/plus-thick.svg" alt="" />
                  Neuer Baum
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="filters-row" v-show="showFilters">
          <v-col cols="12" class="filters-holder">
            <div class="from-to-tree">
              <p class="custom-label">Baum ID interval</p>
              <div class="fields">
                <v-autocomplete v-model="fromTree" item-value="name" :items="dropdownTrees" label="Von |" outlined
                  :item-text="item => `${item.name}`" clearable hide-details></v-autocomplete>
                <v-autocomplete v-model="toTree" item-value="name" :items="dropdownTrees" label="Bis |" outlined
                  :item-text="item => `${item.name}`" clearable hide-details></v-autocomplete>
              </div>
            </div>

            <div class="from-to-dates-baum">

              <div class="date-container">
                <p class="custom-label">Pflanzdatum von</p>
                <v-menu ref="menufromDate" v-model="menufromDate" :close-on-content-click="false"
                  transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="fromDateFormatted" append-icon="mdi-calendar-blank-outline" outlined
                      :placeholder="fromDatePlaceholder" readonly clearable hide-details v-on="on"
                      @click:clear="onClear"></v-text-field>
                  </template>
                  <v-date-picker v-model="fromDate" no-title locale="de" @input="onStartDateSelected">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menufromDate = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menufromDate.save(fromDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="date-container">
                <p class="custom-label">Pflanzdatum bis</p>
                <v-menu ref="menutoDate" v-model="menutoDate" :close-on-content-click="false"
                  transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field v-model="toDateFormatted" append-icon="mdi-calendar-blank-outline" outlined
                      :placeholder="toDatePlaceholder" readonly clearable hide-details v-on="on"
                      @click:clear="onClear"></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" no-title :min="fromDate" locale="de" @input="onEndDateSelected">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menutoDate = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.menutoDate.save(toDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>

            </div>
            <div>
              <label>Farbe</label>
              <v-select class="mt-3" v-model="selectedColor" :items="colorOptions" hide-details outlined></v-select>
            </div>
            <div class="filter-btns">
              <v-btn height="54px" color="#1db954" class="filter-btn" @click="fetchTreesWithParameters">
                Filter
              </v-btn>
              <v-btn size="x-large" height="54px" class="clear" @click="resetFilter">
                <v-icon>mdi mdi-refresh</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-if="!showActualTrees" class="mt-4">
      <v-card-text style="font-size: 15px !important;">
        Geschichte der Bäume
      </v-card-text>
    </v-card>

    <v-alert type="success" elevation="20" v-show="archiveModalVisible" class="alert mt-4">
      Der Baum wurde erfolgreich Weiderhergestellt
    </v-alert>

    <v-alert type="success" elevation="20" v-show="importTreesSuccessfully" class="alert mt-4">
      Import der Bäume erfolgreich abgeschlossen
    </v-alert>

    <v-card :loading="isloading">
      <BaumDatatable :hideSelection="hideSelection" :headers="headers" :tableData="tableData" :showSelect="true"
        :singleSelect="false" :actions="[
          {
            id: 1,
            icons: require('../../../assets/images/icons/eye-outline.svg'),
            action: 'view',
            params: '',
            show: true
          },
          {
            id: 2,
            icons: require('../../../assets/images/icons/pencil-outline.svg'),
            action: 'edit',
            params: '',
            show: false
          },
          {
            id: 3,
            icons: require('../../../assets/images/icons/unarchive-tree.svg'),
            action: 'archive',
            params: '',
            show: false
          },
          {
            id: 4,
            icons: require('../../../assets/images/delete-icon.png'),
            action: 'delete',
            params: '',
            show: true
          }
        ]" @edit="editMethod" @delete="deleteArea" @view="viewMethod" @archive="archiveMethod"
        @selectedRow="getSelectedItems" :userCanView="userCanView" :userCanEdit="userCanEdit"
        :userCanDelete="userCanDelete" :showActualTrees="showActualTrees" />
    </v-card>

    <CustomLoader v-show="isloading" />
  </div>
</template>

<script>
import BaumDatatable from "../../../components/custom_components/BaumDatatable.vue";
import CustomLoader from "../../../components/custom_components/CustomLoader.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../../main";
import { format } from "date-fns";
import { de as deLocale } from "date-fns/locale";

export default {
  components: {
    BaumDatatable,
    CustomLoader
  },
  props: {
    hideFilters: Boolean,
    hideSelection: Boolean
  },
  data() {
    return {
      /* useraccess */
      timeoutSnackbar: 2000,
      snackbar: false,
      snackbarMessage: "",
      userCanEdit: false,
      userCanView: false,
      userCanDelete: false,
      /* end of useraccess */
      menufromDate: false,
      menutoDate: false,
      isloading: true,
      headers: [
      { text: "Bak", value: "colorTypeId" },

        {
          text: "Baum ID",
          align: "start",
          value: "name"
        },
        { text: "Baumart", value: "treeTypeLatinName" },
        { text: "Deutscher Name", value: "treeTypeGermanName" },
        { text: "Alter", value: "age" },
        { text: "Stammumfang [cm]", value: "trunkCircumference" },
        { text: "Kronen Ø [m]", value: "crownDiameter" },
        { text: "Baum Höhe [m]", value: "treeHeight" },
        { text: "", value: "buttons", align: "start", sortable: false }
      ],
      fromDatePlaceholder:format(new Date(), 'dd MMMM yyyy'),
      toDatePlaceholder:format(new Date(), 'dd MMMM yyyy'),
      fromDateFormatted:"",
      toDateFormatted: "",
      tableData: [],
      dropdownTrees:[],
      latLngTree: [],
      inventoryId: null,
      fromDate: "",
      toDate: "",
      fromTree: "",
      toTree: "",
      showFilters: false,
      selectedItems: null,
      showInfo: false,
      selectedColor: null,
      colorOptions: [
        { text: 'Keine', value: null },
        { text: 'Rot', value: 'Red' },
        { text: 'Grün', value: 'Green' },
        { text: 'Blau', value: 'Blue' },
        { text: 'Gelb', value: 'Yellow' },
        { text: 'Grau', value: 'Gray' },
      ],
      showActualTrees: true,
      archiveModalVisible: false,
      importTreesSuccessfully: false,
      cacheTableData:[],
      prevRoute: null
    };
  },
  computed: {
    ...mapGetters(["inventarId", "getTreeItem", "getAreaItem","getTableDataFromSQL","inventarCoordinates","getTreeId","getAreaId"]),
    checkSelectedItems() {
      if (this.selectedItems.length > 0) {
        this.showInfo = true;
      } else {
        this.showInfo = false;
      }
    }
  },
  async mounted() {
    this.inventoryId = this.inventarId;
    this.fetchTreesForDropdown();
    this.fetchTrees();
    this.addTreeCoordinates(null)
  },
  beforeRouteEnter(to, from, next) {
      next(vm => {
          vm.prevRoute = from
      })
  },
  methods: {
    ...mapActions([
      "addTreeCoordinates",
      "addTreeId",
      "addAreaId",
      "addIsTree",
      "addTreeItem",
      "addAreaItem"
    ]),
    goToCreateTree() {
      this.$router.push({
        name: "Neuer Baum",
        params: { inventoryId: this.inventoryId, coord: this.latLngTree, inventarCoord: this.inventarCoordinates }
      });
    },
      onClear() {
        this.fromDate = null;
        this.toDate = null;
    },
    async fetchTreesForDropdown(){
      try {
          const response = axios.get(`https://tilia.rrota.org/api/Tree/GetAllWithParameters/${this.inventarId}`)
            .then(res => {
              if(res.data){
                this.dropdownTrees = res.data
              }
            })
      } catch (error) {
        
      }
    },
    async fetchTreesWithParameters(){
      const paramString = this.createParamString();

      try {
        const response = await axios.get(
          `https://tilia.rrota.org/api/Tree/GetAll/${paramString}`
        );

        /* check if there are data */
        if (Object.keys(response.data).length > 0) {
          this.tableData = response.data.result;
          //eventBus.$emit("onTableFilter");

          /* change user privileges */
          this.userCanDelete = response.data.access.canDelete;
          this.userCanEdit = response.data.access.canEdit;
          this.userCanView = response.data.access.canView;
          this.cacheTableData = this.tableData

        }
        else{
          this.snackbar = true
          this.snackbarMessage = "Diese Daten existieren nicht!"
        }

           this.isloading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
    },
    async fetchTrees() {
      const paramString = this.createParamString();
      if(this.getTableDataFromSQL){
        this.tableData = this.getTableDataFromSQL.result
          this.userCanDelete = this.getTableDataFromSQL.access.canDelete;
          this.userCanEdit = this.getTableDataFromSQL.access.canEdit;
          this.userCanView = this.getTableDataFromSQL.access.canView;
          this.cacheTableData = this.tableData

           this.isloading = false;
      } else {
         try {
          console.log("route", this.$route);

        const response = await axios.get(
          `https://tilia.rrota.org/api/Tree/GetAll/${paramString}`
        );
        console.log("reredads",this.prevRoute);
        if((this.prevRoute?.name == "BaumProfil" || this.prevRoute?.name == "Bearbeiten") && Object.keys(response.data).length > 0){
          console.log("prevName", this.prevRoute);
          
          if(this.getTreeId || this.getAreaId){
            const filteredTable = [...response.data.result]
            let editedItemIndex = null;
            if(this.getTreeId){

              editedItemIndex = filteredTable.findIndex(item => item.id === this.getTreeId );
            } else if(this.getAreaId){
              editedItemIndex = filteredTable.findIndex(item => item.areaId === this.getAreaId);
            }

            if(editedItemIndex !== -1){
              const editedItem = filteredTable.splice(editedItemIndex, 1)[0];
              console.log("editedItem",editedItem);
              filteredTable.unshift(editedItem);
              this.tableData = filteredTable;
              this.userCanDelete = response.data.access.canDelete;
              this.userCanEdit = response.data.access.canEdit;
              this.userCanView = response.data.access.canView;
              this.cacheTableData = this.tableData
            }
          } else {
            this.tableData = response.data.result;
            this.userCanDelete = response.data.access.canDelete;
            this.userCanEdit = response.data.access.canEdit;
            this.userCanView = response.data.access.canView;
            this.cacheTableData = this.tableData
          }
        }
        else if ((this.prevRoute?.name !== "BaumProfil" || this.prevRoute?.name !== "Bearbeiten") && Object.keys(response.data).length > 0) {

          console.log("prevName", this.prevRoute);
          this.tableData = response.data.result;
          this.userCanDelete = response.data.access.canDelete;
          this.userCanEdit = response.data.access.canEdit;
          this.userCanView = response.data.access.canView;
          this.cacheTableData = this.tableData
        }
        else{

          this.tableData = [];
        }

           this.isloading = false;
      } catch (error) {
        console.log(error);
        this.hasError = true;
      }
      }
        
     
    },
    
    async fetchArchivedTrees() {
      if (!this.showActualTrees) {
        const response = await axios.get(
            `https://tilia.rrota.org/api/Tree/GetArchivedTrees/${this.inventoryId}`
          );
        if (Object.keys(response.data).length > 0) {
          this.tableData = response.data;

          this.userCanDelete = true;
          this.userCanEdit = true;
          this.userCanView = true;
        }
        else{
          this.tableData = [];
        }
      }
      else{
        this.resetFilter();
        this.fetchTrees();
        }
    },
    toggleActualTrees() {
      this.showActualTrees = !this.showActualTrees;
    },
    viewMethod(item) {
      console.log("item area",item);
      this.addTreeId(item.id);
      this.addAreaId(item.areaId);
      this.addIsTree(item.isTree);
      this.addTreeCoordinates([item.latitude, item.longitude]);
      this.$router.push({
        name: "BaumProfil",
        params: {
          isTree: item.isTree,
          treeId: item.id,
          areaId: item.areaId,
          coord: [item.latitude, item.longitude],
          userCanEdit: this.userCanEdit
        }
      });
    },
    async deleteArea(item){
      try {
        this.isloading = true
        const response = await axios.delete(`https://tilia.rrota.org/api/Tree/DeleteArea/${item.areaId}`);
        if(response.data){
          this.tableData = this.tableData.filter(dataItem => dataItem.areaId !== item.areaId);
          this.isloading = false
        }
      } catch (error) {
        
        this.isloading = false
        
      }

    },
    editMethod(item) {
      this.addTreeId(item.id);
      this.addAreaId(item.id);
      this.$router.push({
        name: "Bearbeiten",
        params: { treeId: item.id, canDelete: this.userCanDelete, coord: [item.latitude, item.longitude], inventarCoord: this.inventarCoordinates, title: item.name}
      });
    },
    async archiveMethod(item) {
      try {
        const response = await axios.post(`https://tilia.rrota.org/api/Tree/UnarchiveTree/` + item.id);
        this.fetchArchivedTrees();
        this.openModal();
      } catch (error) {
        console.log('Error unarchiving tree', error);
      }
    },
    openModal() {
      this.archiveModalVisible = true;
      setTimeout(() => {
        this.archiveModalVisible = false;
      }, 4000); 
    },
    createParamString() {
      const params = [];
      var invetoryIdd = "";

      if (this.inventoryId !== null && this.inventoryId !== undefined) {
        invetoryIdd = this.inventoryId + "?";
      }

      if (
        this.fromDate !== null &&
        this.fromDate !== undefined &&
        this.fromDate !== ""
      ) {
        params.push(`FromDate=${this.fromDate}`);
      }
      if (
        this.toDate !== null &&
        this.toDate !== undefined &&
        this.toDate !== ""
      ) {
        params.push(`ToDate=${this.toDate}`);
      }

      if (
        this.fromTree !== null &&
        this.fromTree !== undefined &&
        this.fromTree !== ""
      ) {
        params.push(`FromTree=${this.fromTree}`);
      }

      if (
        this.toTree !== null &&
        this.toTree !== undefined &&
        this.toTree !== ""
      ) {
        params.push(`ToTree=${this.toTree}`);
      }
      if(
        this.selectedColor !== null &&
        this.selectedColor !== undefined &&
        this.selectedColor !== ""
      ){
        params.push(`ColorType=${this.selectedColor}`);

      }
      return invetoryIdd + params.join("&");
    },
    filtersTrigger() {
      this.showFilters = !this.showFilters;
    },
    resetFilter() {
      this.fromDate = null;
      this.toDate = null;
      this.fromTree = null;
      this.toTree = null;
      this.toDateFormatted = null;
      this.fromDateFormatted = null;
      this.selectedColor = null;
      this.tableData = []
      this.isloading = true;
      this.fetchTrees()
    },
    onStartDateSelected () {
      if(this.fromDate) {
        this.fromDateFormatted = format(
          new Date(this.fromDate),
            'dd MMMM yyyy',
            { locale:deLocale }
        )
      } else {
        this.fromDateFormatted = format(new Date(),'dd MMMM yyyy', {locale:deLocale});

      }

    },
      onEndDateSelected () {
      if(this.toDate) {
        this.toDateFormatted = format(
          new Date(this.toDate),
            'dd MMMM yyyy',
            { locale:deLocale }
        )
      } else {
        this.toDateFormatted = format(new Date(),'dd MMMM yyyy', {locale:deLocale});

      }

    },
    viewSelectedItemOnMap() {
      if (this.tableData.length > 0 && this.selectedItems) {
        const items = this.tableData.filter(element =>
          this.selectedItems.includes(element)
        );
        const trees = items.map(el => {
          if (el.isTree == true) return el;
        });
        const area = items.map(el => {
          if (el.isTree == false) return el;
        });

        this.addTreeItem(trees);
        this.addAreaItem(area);

        if (this.getAreaItem && this.getTreeItem) {
          this.$router.push({
            name: "Auswahl"
          });
        }
      } else {
        this.showInfo = !this.showInfo;
        setTimeout(() => {
          this.showInfo = false;
        }, 2000);
      }
    },
    getSelectedItems(items) {
      this.selectedItems = items;
      if (this.selectedItems.length > 0) {
        this.showInfo = false;
      }
    },

    async handleFileUpload(event) {
      this.isloading = true;

      const file = event.target.files[0];

      if (!file) {
        return; 
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(`https://tilia.rrota.org/api/Tree/TreeImport?inventoryId=${this.inventoryId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', 
          },
        });
        
        this.importTreesSuccessfully = true;
        this.isloading = false;
        this.fetchTrees();
        setTimeout(() => {
          this.importTreesSuccessfully = false;
        }, 10000); 
      } catch (error) {
        this.isloading = false;

        console.error('Error uploading file:', error);
      }
    },

    async downloadExcel() {
      try {
        const response = await axios.get(
          `https://tilia.rrota.org/api/Tree/TreeExport/${this.inventoryId}`
        ).then(response => {
          window.open(response.data, '_blank');
        }).catch(console.error);
      }
      catch (error) {
        this.isloading = false;
        console.log(error);
        this.hasError = true;
      }
    },
  }
};
</script>

<style lang="scss">
.from-to-dates-baum {
  display: flex !important;
  flex-direction: row !important;
  gap: 30px;
}

// .date-container {
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 16px; /* Adjust the margin as needed */
// }

.first-row {
  .karte {
    margin-right: 10px;
    position: relative !important;
  }
  .information {
    padding: 5px !important;
    color: black !important;
    background: #fff !important;
    position: absolute !important;
    top: -31px !important;
    width: 400px !important;
    left: 20px;
  }

  .import {
    cursor: pointer;
  }
}

.filters-row {
  .filters-holder {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    margin-bottom: 30px;

    .custom-label {
      display: block;
      flex-wrap: nowrap;
    }

    .from-to-tree,
    .from-to-dates {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .fields {
        display: flex;
        gap: 30px;
      }
    }

    .filter-btns {
      display: flex;
      align-items: flex-end;
      gap: 30px;

      .filter-btn {
        color: #000;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        text-transform: none;
        background-color: #1db954;

        .v-btn__content {
          i {
            display: block;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>
